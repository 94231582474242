.MUI-DatepickerWrapper .MuiOutlinedInput-root.Mui-error input {
  color: #ff0000;
}
.MUI-DatepickerWrapper
  .MuiOutlinedInput-root.Mui-error
  .MuiInputAdornment-root
  button
  svg {
  color: #ff0000;
}
