::-webkit-scrollbar {
  background-color: #eaeaea;
  width: 8px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #0b29f9;
  border-radius: 20px;
}

/*.App-logo {*/
/*    height: 40vmin;*/
/*    pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*    .App-logo {*/
/*        animation: App-logo-spin infinite 20s linear;*/
/*    }*/
/*}*/

/*.App-header {*/
/*    background-color: #282c34;*/
/*    min-height: 100vh;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    font-size: calc(10px + 2vmin);*/
/*    color: white;*/
/*}*/

/*.App-link {*/
/*    color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*    from {*/
/*        transform: rotate(0deg);*/
/*    }*/
/*    to {*/
/*        transform: rotate(360deg);*/
/*    }*/
/*}*/

html {
  overflow-y: scroll;
}
